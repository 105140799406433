@import 'styles';

.container {
    @include clickable;
    position: relative;
}

.button {
    $iconSize: 54;
    $googleButtonSize: 38;

    opacity: 0;
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    > div {
        // кнопка невидима так как opacity: 0, но она все еще кликабельна
        // и нам нужно растянуть ее по размеру нашей иконки, чтоб вся область иконки
        // перекрывалась кнопкой гугла и была кликабельна. Сама кнопка от гугла находиться
        // внутри iframe и ее нельзя свободно стилизировать поэтому мы здесь делаем хитрость
        // и просто с помощью scale растягиваем ее до нужных размеров
        scale: calc($iconSize / $googleButtonSize);
    }
}

.disabled {
    pointer-events: none;
    opacity: 0.7;
}
