@import 'styles';

.modal {
    height: 100%;
    width: 100%;
}

.container {
    color: $textColor;
    font-size: 16px;
    padding-top: 20px;
    text-align: justify;

    > ol {
        list-style-type: upper-roman;
        > li {
            &::marker {
                font-weight: bold;
            }
        }
    }
    ul {
        list-style-type: disc;
    }

    p, h3, h4, li {
        font-size: inherit;
        margin: 16px 0;
    }

    a {
        @include clickable;
        color: $textColor;
    }

    table {
        text-align: left;
        border-collapse: collapse;
    }
    th, td {
        border: 1px solid;
        padding: 8px;
    }
}
