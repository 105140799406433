@import 'styles';

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100vh - $headerHeight);
    display: flex;
    justify-content: center;
    background-color: $overlayColor;
    backdrop-filter: blur(20px);
}

.inner {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 595px;
}

.container {
    background-color: $modalBgColor;
    height: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.topMenuWrapper {
    background: $appBg;
}

%activeSubMenuTab {
    color: $textColor;
    background: $accentColor;
}

.subMenuTab {
    color: $textColor;
    font-weight: 600;

    &:hover {
        @extend %activeSubMenuTab;
    }
}

.subMenuTabActive {
    @extend %activeSubMenuTab;
    pointer-events: none;
}

.followButton {
    @include clickable;
    color: $accentColor;
    background: #ffffff;
    border-radius: 20px;
    border: 1px solid $accentColor;

    &::after, &::before {
        display: none;
    }

    span {
        background: unset;
        -webkit-text-fill-color: unset;
    }
}

.userFollowedButton {
    img {
        display: none;
    }
    background: url('/resources/images/assets/ic-check-orange.svg') no-repeat center;
}

.listUserAvatar {
    > div {
        border: 2px solid $textColor;
    }
    width: unset;
    height: unset;
    margin-left: 5px;
}

.listUserName {
    font-weight: normal;
    margin-left: 16px;
}

.topThreeListContainer {
    margin-bottom: 25px;
}
