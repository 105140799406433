@import 'styles';

.form {
    width: 100%;
    max-width: 343px;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    input {
        margin-top: 17px;
    }
}

.btnsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.socialButton {
    margin: 22px 14px 0;
}

.terms {
    margin-top: 34px;
}

.signInBtn {
    height: 40px;
    margin-top: 20px;
}

.signUpBtn {
    align-self: center;
    margin-top: 20px;
}
