.container {
    background-color: #fff;
    padding: 12px;
}

.code {
    height: auto;
    max-width: 100%;
    width: 100%;
}
