.list {
    margin: 40px 0 0;
    font-size: 16px;
    line-height: 20px;
    list-style-type: none;
    padding: 0;
}

.listItem {
    display: flex;
    align-items: center;

    &:not(:first-child) {
        margin-top: 24px;
    }

    img {
        margin-right: 12px;
    }
}

.qrTitle {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    margin-top: 40px;
}

.qrContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    > div:last-child {
        margin-left: 12px;
    }
}

.buttonsContainer {
    margin-top: 32px;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    height: 52px;
}

.button {
    &:not(:first-child) {
        margin-left: 14px;
    }
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
    }
}
