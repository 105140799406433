@import 'styles';

.modal {
    height: 100%;
    width: 100%;
}

.container {
    color: $textColor;
    font-size: 16px;
    padding-top: 20px;
    text-align: justify;

    // 1st level list style
    > ol {
        > li {
            &::marker {
                font-weight: bold;
            }
            // 2nd level list style
            > ol {
                list-style-type: lower-alpha;

            }
        }
    }
    ul {
        list-style-type: disc;
    }

    p {
        font-size: inherit;
        margin: 16px 0;
    }

    li {
        margin: 16px 0;
    }

    a {
        @include clickable;
        color: $textColor;
    }
}
