// sidebar
$sidebarWidth: 320px;

// breakpoints
$mobileMaxWidth: 799px;
$tabletMinWidth: 800px;
$tabletMaxWidth: 1049px;
$desktopMinWidth: 1050px;

// header
$headerHeight: 92px;
$headerHorizontalPadding: 32px;

$buttonBorderRadius: 165px;
$modalBorderRadius: 16px;

// font sizes
$textFontSize: 16px;

// global z-index values
$sidebarZIndex: 300;
$searchOverlayZIndex: 301;
$modalZIndex: 310;
$alertZIndex: 311;
