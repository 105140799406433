@import 'styles';

.overlay {
    flex-grow: 1;
}

.fromHeader {
    max-width: 360px;

    @include mediaTablet {
        .container {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 0;
            max-width: unset;
            transition: width 300ms;
            background-color: $appBg;

            input {
                padding-right: 7px;
                transition: padding-right 300ms;
            }

            &:not(&:global.focused) input {
                @include clickable;
            }

            &:global.focused {
                width: 100%;

                input {
                    padding-right: 17px;
                }
            }
        }
    }
}

.active {
    position: fixed;
    z-index: $searchOverlayZIndex;
    inset: 0;
    max-width: unset;
    background-color: $overlayColor;
}

.container {
    position: relative;
    width: 100%;

    .searchIcon {
        position: absolute;
        left: 17px;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
    }

    input {
        width: 100%;
        height: 44px;
        padding-left: 47px;
        padding-right: 17px;
        border: 1px solid $secondaryTextColor;
        border-radius: $buttonBorderRadius;
        font-size: $textFontSize;
        line-height: 20px;
        color: $textColor;

        &::placeholder {
            color: $secondaryTextColor;
        }

        &:focus {
            border-color: $textColor;
        }
    }
}
