@import 'styles';

.container {
    display: flex;
    align-items: center;

    span {
        font-size: $textFontSize;
        line-height: 28px;
        color: $textColor;
    }
}
