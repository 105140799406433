@import 'styles';

.container {
    $verticalMarginDesktop: 26px;
    $horizontalMarginDesktop: 32px;

    position: absolute;
    top: 0;
    left: 0;
    height: calc(100vh - $headerHeight);
    width: 100%;

    @include mediaTabletAndDesktop {
        margin: $verticalMarginDesktop $horizontalMarginDesktop;
        height: calc(100vh - $headerHeight - $verticalMarginDesktop * 2);
        width: calc(100% - $horizontalMarginDesktop * 2);
    }

    > div {
        background-color: transparent;
    }

    // TODO: change this with theme override in `initializeModule` from streaming-module
    iframe + div > div > div {
        &:first-child {
            border-radius: 12px 12px 0 0;
            overflow: hidden;
        }
        &:last-child {
            background-color: $appBg;

            > div > div {
                padding: 0 0 10px 10px;
            }
        }
    }
}
