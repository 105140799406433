@import 'styles';

.modal {
    height: 100%;
    width: 100%;
}

.container {
    color: $textColor;
    font-size: 16px;
    margin-top: 20px;


    p, h3 {
        font-size: inherit;
        margin: 16px 0;
    }
    h3 {
        font-weight: 600;
    }
    h2 {
        font-size: 17px;
        font-weight: 700;
        text-decoration: underline;
        margin: 24px 0 20px;
    }

    a {
        @include clickable;
        color: $textColor;
    }

    .list {
        li {
            margin: 16px 0;
        }
        .innerList {
            list-style-type: lower-alpha;
        }
    }
}
