@import 'styles';

.prevNextButton {
    svg {
        fill: white !important;
    }
}

.popupWindow {
    background-color: $modalBgColor;

    *:not(input,textarea,select,option) {
        color: $textColor !important;
    }

    border-radius: $modalBorderRadius;
}

.popupCloseButton {
    @include clickable;

    background-image: url('/resources/images/assets/ic-close.svg');
    background-repeat: no-repeat;
    background-position: center;
    top: 4px;
    right: 4px;

    &::before, &::after {
        display: none;
    }
}

.leaderboardFullTab {
    @include clickable;
    color: $textColor;
    border-radius: 30px;
    padding: 4px 16px;
}

.leaderboardFullTabActive {
    pointer-events: none;
    background-color: $accentColor;
}

.primaryButton {
    @include clickable;
    border-radius: $buttonBorderRadius !important;
}

.goalSliderThumb {
    background-color: $accentColor;
}

.goalInput {
    background-color: #fff;
    color: #000;
    font-size: 13px;
    border: 1px solid #eee;
    border-radius: 3px;

    &::placeholder {
        color: #ccc;
    }
}

.customModalActionButton {
    @include clickable;
    background-color: transparent;
    border: 1px solid $textColor;
}
