@import 'styles';

$formSecondaryTextColor: rgba(255,255,255,0.7);

.container {
    width: 327px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.secondaryText {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.154px;
    color: $formSecondaryTextColor;
}

.have {
    composes: secondaryText;
    margin-top: 24px;
    line-height: 22px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    img {
        margin-left: 6px;
        margin-right: 2px;
    }

    .amount {
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        letter-spacing: normal;
    }
}

.packItem {
    margin-top: 24px;
    background-color: $accentColor;
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(2px);
    border-radius: $modalBorderRadius;
    padding: 16px;
    height: auto;

    &:first-child {
        margin-top: 32px;
    }

    > span {
        flex: 1;
        justify-content: space-between;
    }

    .coins {
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;

        > img {
            margin-right: 2px;
        }

        .popular {
            background: #000000;
            border-radius: 8px;
            color: $textColor;
            font-weight: 700;
            font-size: 12px;
            line-height: 1;
            margin-left: 12px;
            padding-right: 6px;
            display: flex;
            align-items: center;

            img {
                margin: 2px;
            }
        }
    }

    .discount {
        position: absolute;
        right: 0;
        top: -8px;
        border-radius: 8px;
        padding: 0 4px;
        background-color: rgba(0,0,0,0.5);
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        display: flex;
        color: $textColor;
    }
}

.terms {
    margin-top: 24px;
    text-align: justify;
}
