@import 'styles';

.container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
    max-width: 60%;
    @include mediaTabletAndDesktop {
        max-width: 30%;
    }
    display: flex;
    flex-direction: column;
    align-items: stretch;
    z-index: $alertZIndex;

    > div:not(:first-child) {
        margin-top: 4px;
    }
}
