@import "styles";

.container {
    width: 100%;
    height: 100%;
    background-color: #1e2023;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $textColor;
    font-weight: 700;

    h2 {
        font-weight: inherit;
        font-size: 36px;
    }

    p {
        margin-top: 16px;
        font-size: 18px;
    }

    a {
        font-size: inherit;
        line-height: inherit;
        color: inherit;
    }
}
