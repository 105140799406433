@import "styles";

.avatar {
    border-radius: 50%;
    object-fit: cover;
}

.placeholder {
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.initials {
    composes: placeholder;
    background-color: $avatarBg;

    span {
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        color: $textColor;
    }
}
