@import 'styles';

.form {
    width: 100%;
    max-width: 343px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.name {
    margin-top: 17px;
}

.birthDate {
    margin-top: 29px;

    input::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
    }
}

.terms {
    margin-top: 24px;
}

.signUpBtn {
    height: 40px;
    margin-top: 20px;
}

.radioContainer {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 28px;

    label:not(:first-child) {
        margin-left: 56px;
    }
}
