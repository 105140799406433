@import 'styles';

.button {
    @include clickable;

    &:disabled {
        pointer-events: none;
        opacity: 0.7;
    }
    position: relative;
}

.secondary {
    border: 1px solid $textColor;
    border-radius: $buttonBorderRadius;
    font-size: $textFontSize;
    line-height: 20px;
    padding: 0 16px;
    height: 44px;
    color: $textColor;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;

    > span {
        display: flex;
        align-items: center;
    }
}

.primary {
    composes: secondary;
    background-color: $accentColor;
    border: 1px solid rgba(255, 255, 255, 0.2);
    font-weight: 600;
}

.loading > span {
    visibility: hidden;
}

.loader {
    height: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
