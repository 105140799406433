.buyCoinsModal {
    padding: 20px 34px 34px;
    background-color: #fafafa;

    > h2 {
        color: #222;
    }

    > button img {
        filter: invert(100%);
    }

    > div {
        overflow: unset;
    }
}
