@import 'styles';

@font-face {
    font-family: 'Montserrat';
    src: url('./resources/fonts/Montserrat.ttf') format('ttf');
}

html, body, #root {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    height: 100%;
    color: $textColor;

}

* {
    font-family: 'Montserrat', -apple-system, sans-serif;
}

div, button, input, h1, h2, h3, h4, h5, h6, p {
    font-size: 0;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

button, input {
    border: 0;
    outline: 0;
    appearance: none;
    background-color: transparent;
    -webkit-tap-highlight-color: transparent;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  display: none;
}

/* Firefox */
input[type=number] {
  appearance: textfield;
}

button {
    cursor: pointer;
}
