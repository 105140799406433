@import './variables.scss';

@mixin mediaMobile {
    @media screen and (max-width: $mobileMaxWidth) {
        @content;
    }
}

@mixin mediaMobileAndTablet {
    @media screen and (max-width: $tabletMaxWidth) {
        @content;
    }
}

@mixin mediaTablet {
    @media screen and (min-width: $tabletMinWidth) and (max-width: $tabletMaxWidth) {
        @content;
    }
}

@mixin mediaTabletAndDesktop {
    @media screen and (min-width: $tabletMinWidth) {
        @content;
    }
}

@mixin mediaDesktop {
    @media screen and (min-width: $desktopMinWidth) {
        @content;
    }
}

@mixin hover {
    @media (hover: hover) {
        &:hover {
            @content;
        }
    }
}

@mixin clickable {
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;

    @include hover {
        opacity: 0.7;
    }
    &:active {
        opacity: 0.5;
    }
}

@mixin imageSize($size) {
    width: $size;
    height: $size;
    min-width: $size;
    min-height: $size;
}
