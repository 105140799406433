@import 'styles';

.header {
    background-color: $appBg;
    font-size: $textFontSize;
    padding: 0 $headerHorizontalPadding;
    height: $headerHeight;

    @include mediaMobile {
        background-color: $appBg2;
        padding: 0 20px;
    }
}

.container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.content {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    > * {
        margin-left: 16px;
    }
}

.burger {
    margin-left: 16px;
}

.profileBtn {
    max-width: 180px;
    > span {
        overflow: hidden;
    }

    @include mediaMobileAndTablet {
        padding: 0;
    }
}

.name {
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 7px;
    font-weight: 600;

    @include mediaMobileAndTablet {
        display: none;
    }
}

.avatar {
    @include imageSize(42px);

    @include mediaDesktop {
        @include imageSize(24px);
    }
}
