@import 'styles';

$searchItemHeight: 169px;

.list {
    box-sizing: border-box;
    background: $appBg;
    border: 1px solid $secondaryTextColor;
    border-radius: $modalBorderRadius;
    position: absolute;
    top: calc(100% + 16px);
    left: 0;
    width: 100%;
    min-height: calc($searchItemHeight + 14px * 2);
    max-height: calc(100vh - $headerHeight - 8px);
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    z-index: $searchOverlayZIndex;
    padding: 3px;
    @include mediaTabletAndDesktop {
        padding: 14px;
    }
}

.emptyItem {
    width: unset !important;
    min-width: 130px !important;
    max-width: unset !important;
    flex: 1;
    margin: 0 2px;
    @include mediaTabletAndDesktop {
        margin: 0 5px;
        min-width: 150px !important;
    }
    @include mediaDesktop {
        min-width: 182px !important;
    }
}

.item {
    composes: emptyItem;
    margin: 3px 2px;
    padding: 0 !important;
    aspect-ratio: 182 / 169;
    @include mediaTabletAndDesktop {
        margin: 6px 5px;
    }
    @include clickable;

    .container {
        border-radius: 12px;
        overflow: hidden;
        position: relative;
        width: 100%;
        height: 100%;
        padding: 16px 12px;
        display: flex;
        flex-direction: column-reverse;
    }

    .name {
        padding: 0;
        position: relative;
        left: 0;
        bottom: 0;
        font-size: 16px;
        line-height: 20px;
        color: $textColor;
        text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.6);
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .avatar {
        border-radius: 0;
        position: absolute;
        width: 100%;
    }
}

.empty {
    composes: list;
    align-items: center;
    justify-content: center;

    span {
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: $textColor;
    }
}

.loading {
    composes: list;
    align-items: center;
    justify-content: center;

    .loader {
        width: 54px;
    }
}

.fromHeader {
    width: calc(100vw - $sidebarWidth - $headerHorizontalPadding * 2);
}

.profileOverlay {
    z-index: $searchOverlayZIndex !important;
    background-color: $overlayColor !important;
}

.popup {
    z-index: $searchOverlayZIndex !important;
}
