@import 'styles';

.form {
    max-width: 415px;
    display: flex;
    flex-direction: column;
    align-items: center;

    > img {
        width: 305px;
        margin-top: 24px;
    }
}

.secondaryText {
    font-size: 12px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.7);
}

.terms {
    composes: secondaryText;
    margin-top: 8px;
    text-align: center;

    a {
        color: inherit;
    }
}


.selected {
    composes: secondaryText;
    margin-top: 16px;
    text-align: center;
}
