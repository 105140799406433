@import 'styles';

.dashboard {
    background-color: $appBg;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: stretch;
}

.rightContent {
    flex-grow: 1;
    overflow-y: auto;
}

.noScroll {
    overflow-y: hidden;
}

.contentContainer {
    position: relative;
    padding: 26px 20px;
    min-height: 100%;

    @include mediaTabletAndDesktop {
        padding: 26px 32px;
    }
}
