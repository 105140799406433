@import 'styles';

.link {
    @include clickable;
    font-weight: 600;
    font-size: $textFontSize;
    line-height: 20px;
    text-decoration-line: underline;
    color: $secondaryTextColor;
}
