@import "styles";

.termsText {
    font-size: 12px;
    line-height: 16px;
    color: $secondaryTextColor;

    a {
        font-size: inherit;
        line-height: inherit;
        color: $textColor;
        text-decoration: none;
    }
}
