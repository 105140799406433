@import 'styles';

.sidebar {
    width: $sidebarWidth;
    min-width: $sidebarWidth;
    background-color: $appBg2;
    padding: 0 20px 20px;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    @include mediaMobile {
        transition: transform 0.3s;
        transform: translateX(-100%);
        width: 60%;
        position: absolute;
        z-index: $sidebarZIndex;
        top: $headerHeight;
        left: 0;
        bottom: 0;
    }
}

.backdrop {
    position: absolute;
    inset: $headerHeight 0 0;
    display: none;
    z-index: calc($sidebarZIndex - 1);

    @include mediaMobile {
        display: block;
        background-color: $overlayColor;
    }
}

.sidebarLogo {
    height: $headerHeight;

    @include mediaMobile {
        display: none;
    }
}

.visible {
    transform: translateX(0);
}

.navList {
    list-style: none;
    padding: 0;
    margin: 32px 0 0;
}

.navItem {
    &:not(:last-child) {
        margin-bottom: 24px;
    }

    a {
        text-decoration: none;
        color: $secondaryTextColor;
        font-weight: 600;
        font-size: $textFontSize;
        line-height: 19.5px;
        display: flex;
        align-items: center;

        @include clickable;

        img {
            margin-right: 19px;
            // the filter here was calculated based on the $secondaryTextColor using
            // this tool https://codepen.io/sosuke/pen/Pjoqqp
            filter: invert(66%) sepia(0%) saturate(7316%) hue-rotate(355deg) brightness(71%) contrast(81%);
        }
    }
}

.active a {
    color: $textColor;

    img {
        filter: none;
    }
}

.disabled a {
    pointer-events: none;
}

.search {
    margin-top: 47px;
}

.footer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.links {
    font-size: 16px;
    font-weight: 600;
    color: $secondaryTextColor;
}
