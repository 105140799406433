@import 'styles';

.overlay {
    $modalTransitionTime: 300ms;

    background-color: $overlayColor;
    position: fixed;
    inset: 0;
    z-index: $modalZIndex;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px;

    // fade-in-out animation
    opacity: 0;
    transition: opacity $modalTransitionTime;
    &Enter {
        opacity: 1;
    }
    &Exit {
        opacity: 0;
    }
}

.container {
    background: $modalBgColor;
    border-radius: $modalBorderRadius;
    padding: 20px 32px;
    position: relative;
    max-height: min(791px, 100%);
    max-width: min(800px, 100%);

    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.title {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
}

.content {
    flex: 1;
    overflow-y: auto;
    // this is needed to make an offset for scrollbar
    margin-right: -20px;
    padding-right: 20px;
}

.closeBtn {
    position: absolute;
    top: 16px;
    right: 16px;
}
