@import "styles";

.container {
    @include clickable;

    font-size: 16px;
    line-height: 20px;
    color: $textColor;
    display: flex;
    align-items: center;
    position: relative;

    input {
        pointer-events: none;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 2px solid $secondaryTextColor;

        &:checked {
            border-color: $accentColor;
        }
    }

    .checkmark {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: $accentColor;
        position: absolute;
        left: 4px;
        top: 4px;
        display: none;
    }

    input:checked ~ .checkmark {
        display: block;
    }

    .label {
        margin-left: 24px;
    }
}

.error {
    input {
        border: 2px solid $errorColor;
    }
    .label {
        color: $errorColor;
    }
}
