@import 'styles';

.container {
    background: rgba(0,0,0,0.7);
    border-radius: 22px;
    display: flex;
    align-items: center;
    padding: 12px;

    // fade-out animation
    $transitionTime: 300ms;
    &Exit {
        transition: opacity $transitionTime;
        opacity: 0;
    }
}

.message {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    flex-grow: 1;
}

.closeBtn {
    margin-left: 12px;

    img {
        width: 16px;
        height: 16px;
    }
}
