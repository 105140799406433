@import 'styles';

.container {
    width: 100%;
    height: 100%;
    background-color: $appBg;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader {
    width: 100px;
    height: 100px;
}
