@import 'styles';

.container {
    position: relative;
}

.textInput {
    font-size: 16px;
    line-height: 20px;
    padding: 11px 0;
    border-bottom: 1px solid $secondaryTextColor;
    color: $textColor;
    width: 100%;

    &::placeholder {
        color: $secondaryTextColor;
    }
    &:focus {
        border-bottom-color: $textColor;
    }
}

.error {
    border-bottom-color: $errorColor;

    &:focus {
        border-bottom-color: $errorColor;
    }
}

.errorText {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    right: 0;
    font-size: 12px;
    line-height: 16px;
    color: $errorColor;
}
